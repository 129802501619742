/**
 * This is legacy code used for the registration section. This will need to be refactored in the future.
 *
 * @returns ENV environment needed for registration URLs
 */

const getEnvironment = (): string => {
  const winLocation = window.location.hostname;
  let env = '';
  switch (true) {
    case winLocation.includes('localhost') ||
      winLocation.includes('portal.eks.apix.dev.aws.jpmchase.net'):
      env = 'sit';
      break;
    case winLocation.includes('portal.eks.apix.uat.aws.jpmchase.net') ||
      winLocation.includes('developer-uat.chase.com'):
      env = 'uat';
      break;
    case winLocation.includes('portal.eks.apix.prod.aws.jpmchase.net') ||
      winLocation.includes('developer.chase.com'):
      env = 'prod';
      break;
    default:
      env = 'prod';
  }
  return env;
};

export default getEnvironment;
